import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";
import { SectionHeader } from "~components/templates/section-header";
import { SectionLayout } from "~components/templates/section-layout";
import { DocumentStyle } from "~components/templates/document-style";
import { PipeDriveForm } from "~components/utils/pipedrive-form";

import * as styles from "./index.module.scss";

const Component: React.FC = () => {
  const CONTACT_FORM_ID =
    "1ElvIjNMP1s6BIT7A3nDw1T0EhWyICsHbFUrqcvXfKMpjISRVwgLqeTqwlhZN34Cn";

  const data = useStaticQuery(graphql`
    query ContactPage {
      contact: file(relativePath: { eq: "contact.svg" }) {
        extension
        publicURL
      }
      thumbnail: file(relativePath: { eq: "contact_ogp.png" }) {
        publicURL
      }
    }
  `);

  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{
        title: "お問い合わせ",
        image: `https://flatt.tech${data.thumbnail.publicURL}`,
        description:
          "株式会社Flatt Securityに関するお問い合わせはこちらのページよりどうぞ。",
      }}
    >
      <SectionLayout>
        <SectionHeader imageSrc={data.contact.publicURL} text="お問い合わせ" />

        <DocumentStyle align="left">
          <p>
            お問い合わせの種類をお選びの上、以下のお問い合わせフォームよりご連絡ください。
            <br />
            内容を確認の上、担当者より折り返しご連絡させていただきます。
          </p>
        </DocumentStyle>

        <div className={styles.Contact}>
          <DocumentStyle align="left">
            <h5 className={styles.Contact_Head}>
              本フォームでお受付するお問い合わせ
            </h5>
            <ul>
              <li className={styles.Contact_List}>
                <a href="/assessment">セキュリティ診断（脆弱性診断）</a>
                に関するご依頼、お問い合わせ
              </li>
              <li className={styles.Contact_List}>
                <a href="/kenro">KENRO</a>に関するご依頼、お問い合わせ
              </li>
              <li className={styles.Contact_List}>
                その他サービスに関するご依頼やお問い合わせ
              </li>
              <li className={styles.Contact_List}>
                報道関係者様からのお問い合わせ、取材・講演のご依頼
              </li>
              <li className={styles.Contact_List}>
                その他、事業に関するお問い合わせ
              </li>
            </ul>
            <p>
              採用応募については専用フォームよりご連絡ください。なお、採用に関する媒体や人材紹介会社様からのお問い合わせについては、本フォームよりご連絡ください。
            </p>
            <li className={styles.Contact_OtherForm}>
              <a href="https://forms.gle/xqizWhqCB7PPUfeG7">
                採用に関するお問い合わせフォーム
              </a>
            </li>
          </DocumentStyle>
        </div>
        <PipeDriveForm formID={CONTACT_FORM_ID} />
      </SectionLayout>
    </PageLayout>
  );
};

export default withContext(Component);
